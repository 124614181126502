<template>
  <div class="login">
    <div class="begin flex items-center">
      <div class="part-1 w-1/2 h-screen">
        <div class="image">
        </div>
      </div>
      <div class="part-2 w-1/2 h-screen overflow-y-scroll">
        <div class="header m-auto px-24">
          <div class="logo">
            <svg-icon
              name="ic_logo"
              original
              class="w-1/3"
            />
          </div><br>
          <div v-if="!isForget" class="body w-10/12 mt-12">
            <login-page
              @updateEmail="updateEmail"
              @updatePassword="updatePassword"
            /><br>
            <div
              v-if="error"
              class="text-red-500 text-center"
            >
              {{ $t('errorLogin') }}
            </div><br>
            <div class="forget text-black underline text-center cursor-pointer" @click="isForget=true">
              {{$t('forgetPassword') }}
            </div><br>
            <div class="login-button">
              <button-base
                :label="$t('loginButton')"
                :is-loading="loader"
                @click.native="sendLogin"
              />
            </div>
          </div>
          <div v-else class="body w-10/12 mt-12">
            <email-write @sendEmail="sendEmail"></email-write>
            <br />
            <div
              v-if="error"
              class="text-red-500 text-center"
            >
              {{ errorMessage }}
            </div><br>
            <div class="login-button">
              <button-base
                :label="$t('continuousButton')"
                :is-loading="loader"
                @click.native="checkEmail"
              />
            </div>
          </div>
          <br>
          <div class="message leading-relaxed w-3/4">
            <span>{{ $t('reservedSpace') }}</span>
            <span class="email-contact text-black cursor-pointer font-semibold">
              <a href="mailto:abpf@abpf.org" class="text-black"> abpf@abpf.org</a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <success-add
      v-if="isSuccess"
      message="Un lien vous a été envoyé par mail pour définir votre nouveau mot de passe."
      button-label="RETOUR"
      @closeSuccess="closeSuccess"
    ></success-add>
  </div>
</template>

<script>
import LoginPage from '../../components/helper/login/login-page'
import ButtonBase from '../../components/helper/add/button-base'
import http from '../../plugins/http'
import apiRoute from '../../router/api-routes'
import EmailWrite from '@/components/helper/login/email-write.vue'
import SuccessAdd from '@/components/helper/success-add.vue'
export default {
  name: 'index',
  components: { SuccessAdd, EmailWrite, ButtonBase, LoginPage },
  data () {
    return {
      error: false,
      loader: false,
      email: '',
      password: '',
      isForget: false,
      errorMessage: '',
      isSuccess: false
    }
  },
  methods: {
    closeSuccess (value) {
      this.isSuccess = value
    },
    checkEmail () {
      if (this.email === '') {
        this.error = true
        this.errorMessage = this.$t('forgetError')
      } else {
        this.error = false
        this.loader = true
        http.post(apiRoute.baseURL + apiRoute.forgetPassword, {
          email: this.email
        }).then(response => {
          console.log(response)
          this.isSuccess = true
        }).catch(error => {
          console.log(error)
          this.loader = false
          if (error.data.message === 'Email address is not exist') {
            this.error = true
            this.errorMessage = 'Adresse email inexistant. Veuillez vérifier votre email'
          }
        })
      }
    },
    sendEmail (value) {
      this.email = value
    },
    sendLogin () {
      if (this.password === '' || this.email === '') {
        this.error = true
      } else {
        this.error = false
        this.loader = true
        http.post(apiRoute.baseURL + apiRoute.login, {
          username: this.email,
          password: this.password
        }).then(response => {
          console.log(response)
          this.$store.dispatch('CREATE_TOKEN', response.token)
          this.$store.dispatch('CREATE_USER', response.person)
          this.$router.push({ path: '/dashboard' })
        }).catch(error => {
          console.log(error)
          this.loader = false
          if (error.data.statusCode === 401) {
            this.error = true
          }
        })
      }
    },
    updatePassword (val) {
      this.password = val
    },
    updateEmail (val) {
      this.email = val
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .part-1 {
    background-color: $pass_login_background;
  }
  .image {
    background-image: url("../../assets/icons/ic_login-page.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
  }
  .login-button::v-deep {
    .button-base {
      .page-button-real {
        width: 100%;
        height: 4rem;
        h4 {
          font-weight: 600;
        }
      }
    }
  }
</style>
