<script>
export default {
  name: 'email-write',
  data () {
    return {
      email: '',
      errorEmail: false
    }
  },
  methods: {
    isEmail () {
      if (!this.validEmail(this.email)) {
        this.errorEmail = true
      } else {
        this.$emit('sendEmail', this.email)
        this.errorEmail = false
      }
    },
    validEmail (email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  }
}
</script>

<template>
  <div class="email-write">
    <div class="begin">
      <div class="content">
        <div class="form">
          <div class="login-form">
            <div class="label-bloc flex flex-col mb-2">
              <label
                for="email"
                class="mb-2 tracking-wide"
              >
                {{ $t('emailLogin') }}
              </label>
              <div class="input-bloc relative">
                <div class="absolute flex border border-transparent left-0 top-0 h-full w-12">
                  <div class="icon flex items-center justify-center rounded-tl-lg rounded-bl-lg z-10 h-full w-full">
                    <svg-icon
                      name="ic_mail"
                      original
                      color="#606060"
                      class="w-6 h-6"
                    />
                  </div>
                </div>
                <input
                  v-model="email"
                  id="email"
                  name="email"
                  type="email"
                  placeholder="sabrina@enabel.be"
                  class="ipt relative w-full border rounded-lg h-16 focus:outline-none py-4 pr-4 pl-12"
                  :class="{'mailError': errorEmail}"
                  @keyup="isEmail"
                >
              </div>
            </div>
            <div
              v-if="errorEmail"
              class="text-red-500 text-sm mt-2"
            >
              {{ $t('emailErrorLogin') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/style/sass/variables";
input {
  color: $black;
  font-weight: 500;
}
.icon, .ipt {
  background-color: $light_gray3;
}
.ipt:focus {
  border-color: $pass_yellow;
  opacity: 0.7;
}
.mailError, .passwordError {
  border-color: $pass_error_red;
  &:focus {
    border-color: $pass_error_red;
  }
}
</style>
